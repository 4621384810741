import React, { useState, useMemo } from 'react';
import { useForm, useDebouncedCallback } from 'utils/hooks';
import { TextField, Icon, Segments, AutoCompleteSearch, Checkbox } from 'components';
import Popover from '@mui/material/Popover';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Search from '@mui/icons-material/Search';
import omitBy from 'lodash/omitBy';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import {
  FilterType,
  ActiveFilterType,
  FilterButtonPropType,
  FilterSelectOptionType,
  SearchType,
} from './FilterButtonV3.types';
import cn from 'classnames';
import styles from './style.module.scss';

const EXCEPTIONS_FIELD = 'exceptions';

// TODO: Add preset filters and checkbox group filter
export default function FilterButtonV3({
  initialValue,
  defaultFilters,
  presets,
  searches,
  exceptions,
  filters,
  disabled,
  onChange,
}: FilterButtonPropType) {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const isPopoverOpen = Boolean(anchorEl);
  const popoverId = isPopoverOpen ? 'popover-element' : undefined;
  const [activeSearches, setActiveSearches] = useState<string[]>(() => {
    return (searches || []).reduce<string[]>((res, search, index, arr) => {
      if (initialValue?.[search.id]) {
        res.push(search.id);
      }
      // Show the first search if there is no active search
      if (res.length === 0 && index === arr.length - 1) {
        res.push(arr[0].id);
      }
      return res;
    }, []);
  });

  const handleChangeSearch = (oldSearchId: string) => (e: any) => {
    const newSearchId = e.target.value;
    setActiveSearches(activeSearches.map((searchId) => (searchId === oldSearchId ? newSearchId : searchId)));
    const oldSearchValue = form.getField(oldSearchId);
    // If old search value is empty, do not update the filter
    if (!oldSearchValue) {
      return;
    }
    // Swap values
    form.setFields({ [oldSearchId]: '', [newSearchId]: oldSearchValue });
    setTimeout(() => {
      onChange(form.values);
    });
  };

  const handleAddAdditionalSearch = () => {
    // Find the first search item that was not added
    const newSearchId = searches?.find(({ id }) => !activeSearches.includes(id))?.id;
    if (newSearchId) {
      setActiveSearches([...activeSearches, newSearchId]);
    }
  };

  const onFilterChangeHandler = useDebouncedCallback((values) => {
    onChange(values);
  }, 600);

  const { bind, bindCustom, form } = useForm(initialValue, {
    onChange: (e: any, values: any) => {
      onFilterChangeHandler.callback(values);
    },
  });

  const onClickHandler = (e: any) => {
    if (disabled) {
      return;
    }
    setOpen(true);
    setAnchorEl(e.currentTarget);
  };

  const resetFilters = () => {
    form.clear();
    form.setFields(defaultFilters);
    if (searches?.length) {
      setActiveSearches([searches[0].id]);
    }
    onChange(defaultFilters); // not calling the debounced callback to make clear instant
  };

  const getActiveFilters = (): ActiveFilterType[] => {
    const activeFilters: ActiveFilterType[] = [];
    presets?.forEach((preset) => {
      if (form.values[preset.id]) {
        activeFilters.push({
          id: preset.id,
          label: preset.label,
        });
      }
    });
    Object.keys(form.values).forEach((key) => {
      const value = form.values[key];
      const filter = filters?.find((f) => f.id === key);
      if (activeSearches.includes(key)) {
        if (value) {
          activeFilters.push({
            id: key,
            label: `${searches?.find(({ id }) => id === key)?.label || ''}: ${
              Array.isArray(value) ? value.join(', ') : value
            }`,
          });
        }
        return;
      }
      const originalFilterObj: FilterType | undefined = filters?.find((f) => f.id === key);
      const isActive =
        !!originalFilterObj &&
        !originalFilterObj.hidden &&
        (Array.isArray(value) ? value.length > 0 : !isNil(value) && value !== '');
      if (isActive) {
        let valueLabel = '';
        if (filter && (filter.type === 'select' || filter.type === 'select-multiple' || filter.type === 'segments')) {
          const selectedOptions = (filter.options || []).filter((option) =>
            Array.isArray(value) ? value.includes(option.value) : option.value === value
          );
          valueLabel = selectedOptions.map((option) => option.label).join(', ') || value;
        } else if (typeof value == 'boolean') {
          valueLabel = value ? 'Yes' : 'No';
        } else {
          valueLabel = value;
        }

        activeFilters.push({
          id: originalFilterObj.id,
          label: `${originalFilterObj.label}: ${valueLabel}`,
          permanent: originalFilterObj.permanent,
        });
      }
    });
    exceptions?.forEach((exception) => {
      if ((form.values[EXCEPTIONS_FIELD] || []).includes(exception.id)) {
        activeFilters.push({
          id: exception.id,
          label: exception.label,
          isException: true,
        });
      }
    });
    return activeFilters;
  };

  const removeExceptionFilter = (exceptionId: string) => {
    const { onChange: onExceptionChange, value = [] } = bind(EXCEPTIONS_FIELD);
    onExceptionChange({ target: { value: value.filter((id: string) => id !== exceptionId) } });
  };

  const clearFilter = (field: string) => {
    form.clearField(field);
    setTimeout(() => {
      onChange(form.values);
    });
  };

  const fieldProps = {
    fullWidth: true,
    variant: 'outlined',
    InputLabelProps: {
      shrink: true,
    },
  };

  const activeFilters = useMemo(() => getActiveFilters(), [form.values, filters]);
  const hasChanges = useMemo(
    () =>
      !isEqual(
        omitBy(
          form.values,
          (value) =>
            value === null || value === undefined || value === '' || (Array.isArray(value) && value.length === 0)
        ),
        defaultFilters || {}
      ),
    [form.values, defaultFilters]
  );

  return (
    <div className={styles.container}>
      <Icon
        className={cn(styles.preferencesIcon, {
          [styles.preferencesIconActive]: open || activeFilters.length > 0,
          [styles.preferencesIconDisabled]: disabled,
        })}
        name='preferences'
        onClick={onClickHandler}
      />
      {!disabled && activeFilters.length > 0 && (
        <>
          {activeFilters.map((filter: ActiveFilterType) => {
            return (
              <div
                key={filter.id}
                className={cn(styles.inlineFilter, {
                  [styles.permanentFilter]: filter.permanent,
                })}>
                {filter.label}
                {!filter.permanent && (
                  <Icon
                    className={styles.deleteFilterIcon}
                    name='x'
                    size={20}
                    onClick={(e: any) => {
                      e?.stopPropagation();
                      if (filter.isException) {
                        removeExceptionFilter(filter.id);
                      } else {
                        clearFilter(filter.id);
                      }
                    }}
                  />
                )}
              </div>
            );
          })}
          {hasChanges && (
            <p className={styles.clearFilters} onClick={resetFilters}>
              Clear Filters
            </p>
          )}
        </>
      )}
      <Popover
        id={popoverId}
        open={isPopoverOpen}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
          setOpen(false);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}>
        <div className={styles.popoverContainer}>
          <Grid container spacing={2}>
            {presets && presets.length > 0 && (
              <>
                <Grid item xs={12}>
                  <label className={styles.searchLabel}>Preset Views</label>
                </Grid>
                <Grid item xs={12}>
                  {presets.map((preset) => {
                    const inputCommonProps = { ...bind(preset.id) };
                    return (
                      <div
                        key={preset.id}
                        className={cn(styles.presetFilter, {
                          [styles.presetFilterActive]: inputCommonProps.value,
                        })}
                        onClick={() => inputCommonProps.onChange({ target: { value: !inputCommonProps.value } })}>
                        {preset.label}
                      </div>
                    );
                  })}
                </Grid>
              </>
            )}
            {searches && searches.length > 0 && (
              <Grid item xs={12}>
                <label className={styles.searchLabel}>Search</label>
              </Grid>
            )}
            {activeSearches.map((searchId, index) => {
              const inputCommonProps = { ...bind(searchId) };
              const activeSearch = searches?.find((search) => search.id === searchId);
              return (
                <React.Fragment key={searchId}>
                  <Grid item xs={6}>
                    <TextField {...fieldProps} select value={searchId} onChange={handleChangeSearch(searchId)}>
                      {searches
                        ?.filter(({ id }) => id === searchId || !activeSearches.includes(id))
                        ?.map((option: SearchType) => {
                          const { label: _label, id: value } = option;
                          return (
                            <MenuItem key={value} value={value}>
                              {_label}
                            </MenuItem>
                          );
                        })}
                    </TextField>
                  </Grid>
                  <Grid item xs={activeSearches.length > 1 ? 5 : 6}>
                    {activeSearch?.type === 'text' && (
                      <TextField
                        id={`filter-field-${searchId}`}
                        {...fieldProps}
                        placeholder='Search for...'
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position='start'>
                              <Search />
                            </InputAdornment>
                          ),
                        }}
                        {...inputCommonProps}
                      />
                    )}
                    {activeSearch?.type === 'auto-complete-search' && (
                      <AutoCompleteSearch
                        value={inputCommonProps.value}
                        placeholder='Search for...'
                        onChange={(newValue: string) => inputCommonProps.onChange({ target: { value: newValue } })}
                        fetchItems={activeSearch?.fetchItems}
                      />
                    )}
                  </Grid>
                  {activeSearches.length > 1 && (
                    <Grid container item xs={1} direction='row' alignItems='center'>
                      <Icon
                        name='unavailable-minus-negative'
                        color='actionable-green'
                        onClick={() => {
                          if (inputCommonProps.value) {
                            inputCommonProps.onChange({ target: { value: '' } });
                          }
                          const newActiveSearches = [...activeSearches];
                          newActiveSearches.splice(index, 1);
                          setActiveSearches(newActiveSearches);
                        }}
                      />
                    </Grid>
                  )}
                </React.Fragment>
              );
            })}
            {searches && activeSearches.length < searches.length && (
              <Grid item xs={12}>
                <Button className={styles.addButton} onClick={handleAddAdditionalSearch}>
                  <Icon name='add-circle' size={20} />
                  &nbsp;&nbsp;Add Additional Search Parameter
                </Button>
              </Grid>
            )}
            {(filters || []).map((filter: FilterType) => {
              const {
                id,
                label,
                type,
                options,
                hidden,
                loading,
                placeholder,
                maxSelections,
                maxSelectionsHelperText,
                size,
                fetchItems,
              } = filter;
              if (hidden) {
                return null;
              }

              const inputCommonProps = {
                ...(type === 'date' ? bindCustom : bind)(id, {
                  required: false,
                  defaultValue: type === 'select-multiple' ? [] : '',
                }),
                id: `filter-field-${id}`,
                placeholder: label,
                ...fieldProps,
              };

              let fieldJsx = null;

              if (['text', 'number'].includes(type)) {
                fieldJsx = <TextField {...inputCommonProps} type={type} />;
              }

              if (type === 'auto-complete') {
                fieldJsx = (
                  <Autocomplete
                    loading={loading}
                    options={options || []}
                    value={
                      inputCommonProps.value ? options?.find((option) => option.value === inputCommonProps.value) : null
                    }
                    isOptionEqualToValue={(option: FilterSelectOptionType, value) =>
                      !!option.id && option.id === value?.id
                    }
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => <TextField {...params} placeholder={placeholder} variant='outlined' />}
                    onChange={(_event, value) => {
                      // Prevent updating filter when clearing input
                      if (inputCommonProps.value || value?.value) {
                        inputCommonProps.onChange({ target: { value: value?.value || '' } });
                      }
                    }}
                  />
                );
              } else if (type === 'auto-complete-search') {
                fieldJsx = (
                  <AutoCompleteSearch
                    value={inputCommonProps.value}
                    placeholder={placeholder}
                    onChange={(newValue: string) => inputCommonProps.onChange({ target: { value: newValue } })}
                    fetchItems={fetchItems}
                  />
                );
              } else if (type === 'auto-complete-multiple') {
                const selectedValue = (options || []).filter((option) =>
                  inputCommonProps.value?.includes(option.value)
                );
                const isMaxLimitReached = !!maxSelections && maxSelections <= selectedValue.length;
                fieldJsx = (
                  <Autocomplete
                    loading={loading}
                    multiple
                    limitTags={1}
                    options={options || []}
                    value={selectedValue}
                    isOptionEqualToValue={(option: FilterSelectOptionType, value) =>
                      !!option.id && option.id === value?.id
                    }
                    getOptionDisabled={(option) => isMaxLimitReached && !selectedValue.includes(option)}
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        helperText={isMaxLimitReached && maxSelectionsHelperText}
                        placeholder={placeholder}
                        variant='outlined'
                      />
                    )}
                    onChange={(_event, value) =>
                      inputCommonProps.onChange({ target: { value: value.map((item) => item.value) || [] } })
                    }
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => {
                        const { key, onDelete, tabIndex } = { ...getTagProps({ index }) };
                        return (
                          <div key={key} className={styles.tag} tabIndex={tabIndex}>
                            <span>{option.label}</span>
                            <Icon name='x' color='white' size={18} onClick={onDelete} />
                          </div>
                        );
                      })
                    }
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <div className={styles.checkmark}>
                          {selected && <Icon name='checkmark' size={24} color='actionable-green' />}
                        </div>
                        {option.label}
                      </li>
                    )}
                  />
                );
              } else if (type === 'select' || type === 'select-multiple') {
                fieldJsx = (
                  <TextField
                    {...inputCommonProps}
                    select
                    {...(type === 'select-multiple' ? { SelectProps: { multiple: true } } : {})}>
                    {options?.map((option: FilterSelectOptionType) => {
                      const { label: _label, value, header } = option;
                      return !header ? (
                        <MenuItem
                          key={value}
                          value={value}
                          {...(type === 'select' ? { style: { textTransform: 'capitalize' } } : {})}>
                          {_label}
                        </MenuItem>
                      ) : (
                        <MenuItem disabled>{_label}</MenuItem>
                      );
                    })}
                  </TextField>
                );
              } else if (type === 'date') {
                fieldJsx = (
                  <DatePicker
                    componentsProps={{
                      actionBar: { actions: ['clear'] },
                    }}
                    value={!inputCommonProps.value ? null : inputCommonProps.value}
                    onChange={(date) => {
                      if (!date?.isValid()) {
                        inputCommonProps.onChange('');
                      } else {
                        inputCommonProps.onChange(date?.format('YYYY-MM-DD'));
                      }
                    }}
                    inputFormat='MM/DD/YYYY'
                    renderInput={(params) => <TextField {...params} {...fieldProps} />}
                  />
                );
              } else if (type === 'segments') {
                fieldJsx = (
                  <Segments
                    name={id}
                    value={inputCommonProps.value}
                    onChange={(value) => inputCommonProps.onChange({ target: { value } })}
                    items={options as any}
                  />
                );
              }

              if (!fieldJsx) return null;
              return (
                <Grid key={id} item xs={size === 'full' ? 12 : 6}>
                  <label className={styles.filterLabel}>{label}</label>
                  {fieldJsx}
                </Grid>
              );
            })}
            {exceptions && exceptions.length > 0 && (
              <Grid item xs={6}>
                <label className={styles.filterLabel}>Exceptions</label>
                <Grid item container>
                  <Checkbox
                    value={form.getField(EXCEPTIONS_FIELD)?.length === exceptions.length}
                    name='all'
                    fullWidth
                    label='All'
                    onClick={(e: any) => {
                      const { onChange: exceptionOnChange } = bind(EXCEPTIONS_FIELD);
                      exceptionOnChange({
                        target: {
                          value: e.target.checked ? (exceptions || []).map((exception) => exception.id) : [],
                        },
                      });
                    }}
                  />
                  {exceptions?.map((option) => (
                    <div key={option.label} style={{ width: '100%' }}>
                      <Checkbox
                        label={option.label}
                        name={option.id}
                        fullWidth
                        value={!!form.getField(EXCEPTIONS_FIELD)?.find((id: string) => id === option.id)}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const { onChange: exceptionOnChange, value: selectedExceptions = [] } =
                            bind(EXCEPTIONS_FIELD);
                          const checked = e.target.checked;
                          exceptionOnChange({
                            target: {
                              value: checked
                                ? [...selectedExceptions, option.id]
                                : selectedExceptions.filter((id: string) => id !== option.id),
                            },
                          });
                        }}
                      />
                    </div>
                  ))}
                </Grid>
              </Grid>
            )}
          </Grid>
        </div>
      </Popover>
    </div>
  );
}
